
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, inject } from "vue";
import moment from "moment";
import { Socket } from "socket.io-client";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  setup() {
    const notifications = ref<any[]>([]);
    const store = useStore();
    const { t } = useI18n();
    const socket: Socket = inject("socket") as Socket;

    const updateData = () => {
      ApiService.get("/notifications")
        .then(({ data }) => {
          notifications.value = [];
          let nb = 0;
          for (let i = 0; i < data.length; i++) {
            // eslint-disable-next-line
            nb++;
            if (data[i].refType == "newComment") data[i].link = "/actualites";
            else data[i].link = "/";

            notifications.value.push(data[i] as never);
            if (nb > 4) break;
          }
        })
        .catch((e) => console.log(e));
    };

    updateData();

    return {
      notifications,
      moment,
      t,
    };
  },
});
