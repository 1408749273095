
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

interface Filter {
  name: string;
  year: string;
  trimester: string;
}
interface SchoolarYears {
  _id: string;
}
export default defineComponent({
  name: "dropdown-1",
  components: {},
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const availavleSchoolarYears = ref<SchoolarYears[]>([
      {
        _id: "",
      },
    ]);
    const SchoolarYears = ref([
      {
        _id: "",
        name: "",
      },
    ]);
    const activeYearName = ref<any>("");
    ApiService.post("/schoolaryears/filter", {
      query: { $query: {}, $orderby: { end: -1 } },
    }).then((res) => {
      const data = res.data.map((sc) => {
        return {
          ...sc,
          name:
            new Date(sc["start"]).getFullYear() +
            "-" +
            new Date(sc["end"]).getFullYear(),
        };
      });
      SchoolarYears.value = data;
      let studentYears = Object.keys(
        store.getters.currentStudent.schoolarYearsHistory
      );
      availavleSchoolarYears.value = data.filter((i) =>
        studentYears.includes(i._id)
      );
      //if (vendors.filter(e => e.Name === 'Magenic').length > 0) {
      /* vendors contains the element we're looking for */
    });

    const data = ref<Filter>({
      name: store.getters.currentStudent._id,
      year: store.getters.currentYear._id,
      trimester: store.getters.currentTrimester,
    });
    const handleSettings = () => {
      setCurrentYear();
      store.commit("setTrimester", data.value.trimester);
      window.location.reload();
    };
    const setCurrentYear = () => {
      // console.log("currentYear", data.value.year);
      store.commit(
        "setCurrentYear",
        SchoolarYears.value.find((obj) => {
          return obj._id === data.value.year;
        })
      );
      window.localStorage.setItem("activeSchoolarYear", data.value.year);
    };
    const setCurrentStudent = () => {
      // console.log(data.value.name);
      let activeStudent = store.getters.currentUser.students.find(
        (i) => i._id === data.value.name
      );
      //   console.log(activeStudent);
      let studentYears = Object.keys(activeStudent.schoolarYearsHistory);
      availavleSchoolarYears.value = SchoolarYears.value.filter((i) =>
        studentYears.includes(i._id)
      );
      //   console.log("availavleSchoolarYears", availavleSchoolarYears.value[0]);
      data.value.year =
        availavleSchoolarYears.value[
          availavleSchoolarYears.value.length - 1
        ]._id;
      window.localStorage.setItem("activeSchoolarYear", data.value.year);
      store.commit(
        "setCurrentYear",
        SchoolarYears.value.find((obj) => {
          return obj._id === data.value.year;
        })
      );
      store.commit("setStudent", activeStudent);
    };
    return {
      t,
      setCurrentStudent,
      setCurrentYear,
      handleSettings,
      availavleSchoolarYears,
      store,
      activeYearName,
      data,
    };
  },
});
