
import { defineComponent, ref } from "vue";
import DropdownSettings from "@/components/dropdown/parametre.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: { type: String, default: "xxx" },
  },
  components: {
    DropdownSettings,
  },
  setup() {
    const { t, te } = useI18n();
    const key = ref(0);
    const update = () => {
      console.log(key);
      key.value += 1;
    };
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    return {
      t,
      update,
      translate,
      toolbarWidthFluid,
    };
  },
});
