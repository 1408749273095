
import { computed, defineComponent, ref } from "vue";
import { themeName, demo } from "@/core/helpers/documentation";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import moment, { Moment } from "moment";
import { AxiosRequestConfig } from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { event } from "vue-gtag";

// eslint-disable-next-line
declare var MediaRecorder: any;

class WebRecording {
  public constraints: { audio: boolean; video?: boolean };
  public chunks: Blob[];
  public chunkTime: number;
  // eslint-disable-next-line
  public recorder: any;
  public isStoped: boolean;
  public isReady: boolean;

  constructor() {
    this.constraints = { audio: true };
    this.chunks = [];
    this.chunkTime = 1000;
    this.recorder = null;
    this.isStoped = false;
    this.isReady = false;

    // eslint-disable-next-line
    const mediaNavigator =
      (window.navigator as any).getUserMedia ||
      (window.navigator as any).mozGetUserMedia ||
      (window.navigator as any).msGetUserMedia ||
      (window.navigator as any).webkitGetUserMedia;
    if (!mediaNavigator) {
      throw Error("mediaNavigator not supported");
    }

    navigator.mediaDevices
      .getUserMedia(this.constraints)
      .then((stream) => {
        this.recorder = new MediaRecorder(stream);
        this.recorder.ondataavailable = (event) => {
          this.chunks.push(event.data);
          if (this.isReady) {
            this.chunkReadyHandler(event.data);
          }
        };
        this.recorder.onstop = () => {
          const recordStream = new Blob(this.chunks, {
            type: "audio/mp3;codecs=opus",
          });
          if (this.isStoped) {
            this.stopHandler(recordStream);
          }
        };
      })
      .catch((e) => {
        throw "web recording error: " + e.message;
      });
  }

  startRecord() {
    this.chunks = [];
    if (!this.recorder) {
      throw Error("MediaRecorder not available");
    }
    this.recorder.start(this.chunkTime);
  }

  stopRecord() {
    if (!this.recorder) {
      throw Error("MediaRecorder not available");
    }
    this.recorder.stop();
  }

  onStop(handler: any) {
    this.isStoped = true;
    this.stopHandler = handler;
  }

  onChunkReady(handler) {
    this.isReady = false;
    this.chunkReadyHandler = handler;
  }

  // eslint-disable-next-line
  chunkReadyHandler(a: any) {}

  // eslint-disable-next-line
  stopHandler(b: any) {}

  setChunkTime(timeMs) {
    this.chunkTime = timeMs;
  }
}

export default defineComponent({
  name: "kt-explore",

  components: {},

  setup() {
    const { t } = useI18n();
    const text = ref("");

    const uploadPercentage = ref(0);

    const recorder = ref<WebRecording | null>(null);
    const stream = ref<string | null>(null);
    const isStarted = ref(false);
    const show = ref(true);

    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    function onReady(streamm) {
      const audioURL = window.URL.createObjectURL(streamm);
      stream.value = audioURL;
    }

    function onEnable() {
      recorder.value = new WebRecording();
      recorder.value.onStop(onReady);
    }
    function onStart() {
      isStarted.value = true;
      recorder.value?.startRecord();
    }
    function onStop() {
      isStarted.value = false;
      recorder.value?.stopRecord();
    }

    const timer = ref<Moment>(moment());

    setInterval(() => {
      if (isStarted.value) timer.value = moment(timer.value.add(1, "seconds"));
    }, 1000);

    const counter = computed(() => {
      return timer.value.format("mm:ss");
    });

    const sendFeedback = () => {
      // eslint-disable-next-line
      submitButtonRef.value!.disabled = true;
      submitButtonRef.value?.setAttribute("data-kt-indicator", "on");

      const data = new FormData();

      if (feedback.value.desc) data.append("text", feedback.value.desc);

      data.append("categories", JSON.stringify(feedback.value.categories));

      data.append("rating", feedback.value.rating);

      if (stream.value) {
        const d = new Blob(recorder.value?.chunks, {
          type: "audio/mp3;codecs=opus",
        });
        data.append("audio", d, "audio.mp3");
      }

      feedback.value.uploadFiles.forEach((file) => {
        data.append("resource", file.raw, file.raw.name);
      });

      ApiService.put(`/feedbacks`, data as AxiosRequestConfig, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          uploadPercentage.value = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then(() => {
          event("Send feedback", {
            event_category: "Feedback",
            event_label: "Feedback tab",
            value: 1,
          });
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
          document.body.setAttribute("data-kt-drawer-overlay", "false");
          show.value = false;
          const drawer = document.getElementById("kt_explore") as HTMLElement;
          drawer.classList.remove("drawer-on");
          document.body.removeAttribute("data-kt-drawer-explore");
          document.body.removeAttribute("data-kt-drawer");

          //remove element with class drawer-overlay
          const drawerOverlay =
            document.getElementsByClassName("drawer-overlay");
          if (drawerOverlay.length > 0) {
            drawerOverlay[0].remove();
          }

          feedback.value = {
            categories: [],
            uploadFiles: [],
            rating: 3,
          };
          stream.value = null;
          recorder.value = null;
          isStarted.value = false;

          Swal.fire({
            text: t("feedback.successSent"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
          uploadPercentage.value = 0;
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const feedback = ref<any>({
      categories: [],
      uploadFiles: [],
      rating: 3,
    });

    // eslint-disable-next-line prettier/prettier
    const updateUploadFiles = (file, files: any) => {
      feedback.value.uploadFiles = files;
    };

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    return {
      stream,
      timer,
      recorder,
      counter,
      isStarted,
      onEnable,
      onStart,
      onStop,
      updateUploadFiles,
      feedback,
      t,
      text,
      themeName,
      demo,
      sendFeedback,
      moment,
      submitButtonRef,
      show,
      uploadPercentage,
      customColorMethod,
    };
  },
});
