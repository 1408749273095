
import { defineComponent, inject, computed, ref } from "vue";
import { useStore } from "vuex";
import { Socket } from "socket.io-client";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import ApiService from "@/core/services/ApiService";
import { event } from "vue-gtag";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    KTNotificationsMenu,
  },
  setup() {
    const store = useStore();
    const parentId = window.localStorage.getItem("parentId");
    const parentToken = JwtService.getToken();
    const socket: Socket = inject("socket") as Socket;
    const count = ref(0);
    var titleLoop;
    let test = true;
    const notifications = ref(0);
    const ptcount = ref(0);

    const onMessageNotification = () => {
      count.value++;
      newNotify();
      const audio = new Audio("/media/notification.mp3");
      audio.play();
    };

    socket.on("messageNotification", onMessageNotification);

    socket.on("pt-messageNotification", onMessageNotification);

    const toggleChat = () => {
      event("Open chat adminstration", {
        event_category: "Chat adminstration",
        event_label: "Chat adminstration section",
        value: 1,
      });
      count.value = 0;
      resetNotification();
    };

    socket.emit("parentJoin", {
      parentId,
      token: parentToken,
    });

    socket.on("parentJoind", async () => {
      count.value = 0;
      await ApiService.get(`/chat/parent/unseenMessages`).then(({ data }) => {
        count.value += Number(data);
      });

      ApiService.get(`/pt-chat/parent/unseenMessages`).then(({ data }) => {
        count.value = 0;
        data.forEach((d) => {
          count.value += Number(d.count);
        });
      });
    });

    const newNotify = () => {
      clearInterval(titleLoop);
      titleLoop = setInterval(() => {
        if (test) document.title = "EdTrust | Contrôle parental";
        else document.title = "Nouveaux messages";
        test = !test;
      }, 1500);
    };

    const resetNotification = () => {
      clearInterval(titleLoop);
      document.title = "EdTrust | Contrôle parental";
    };

    const togglePTChat = () => {
      ptcount.value = 0;
      resetPTNotification();
    };

    const resetPTNotification = () => {
      clearInterval(titleLoop);
      document.title = "EdTrust | Contrôle parental";
    };

    return {
      store,
      count,
      toggleChat,
      resetNotification,
      notifications,
      ptcount,
      togglePTChat,
      resetPTNotification,
      buildingConfigValue: computed(() => store.getters.buildingConfigValue),
    };
  },
});
