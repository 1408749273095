
import { defineComponent, computed, ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { Socket } from "socket.io-client";
import { event } from "vue-gtag";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const { t } = useI18n();
    const parents = ref(store.getters.currentUser);
    const socket: Socket = inject("socket") as Socket;

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "ar";

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: `${t("dashboard.english")}`,
      },

      fr: {
        flag: "media/flags/france.svg",
        name: `${t("dashboard.french")}`,
      },

      ar: {
        flag: "media/flags/tunisia.svg",
        name: `${t("dashboard.arabic")}`,
      },
    };

    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() => {
        socket.emit("parentLeave");
        socket.removeAllListeners();
        router.push({ name: "sign-in" });
      });
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    const token = JwtService.getToken();

    const openEdStudent = () => {
      event("Open EdStudent", {
        event_category: "Espace eleve",
        event_label: "Profile menu",
        value: 1,
      });
    };

    return {
      t,
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      store,
      parents,
      token,
      EDStudent_url: process.env.VUE_APP_EDSTUDENT_URL,
      openEdStudent,
    };
  },
});
